import React from "react";

import Layout from "../components/Layout";

import ACEContact from "../components/ACEContact";

const ACE24Page = () => {
  return (
    <Layout bg="home-bg" isDisplayBottomPartner={false} isDisplayHeader={false}>
      <ACEContact formId="ace-contact-form" />
    </Layout>
  );
};

export default ACE24Page;
